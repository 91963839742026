import React, { useState, useEffect } from "react";
import { getLogList } from "../api";
import formatDate from "../helpers/formatDate";

export default () => {
  const [list, setList] = useState([]);

  const [description, setDescription] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    getLogList()
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const resetFilters = () => {
    setDescription("");
    setUserName("");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // marginTop: 16,
        padding: 16,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: 16,
        }}
      >
        <input
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          placeholder="Ime korisnika"
        />
        <span style={{ height: 8 }} />
        <input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Zapis"
        />
        <span style={{ height: 8 }} />
        <span style={{ height: 8 }} />
        <span style={{ height: 8 }} />
        {(userName || description) && (
          <button className="btn gray" onClick={resetFilters}>
            Resetuj filtere
          </button>
        )}
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th className="center">Datum</th>
              <th>Korisnik</th>
              <th>Zapis</th>
            </tr>
          </thead>
          <tbody>
            {list
              .filter((log) =>
                userName
                  ? (
                      log.user.fname.toLowerCase() +
                      log.user.lname.toLowerCase()
                    ).includes(userName.toLowerCase())
                  : true && description
                  ? log.description
                      .toLowerCase()
                      .includes(description.toLowerCase())
                  : true
              )
              .map((log, i) => (
                <tr key={log.id}>
                  <td className="center">{formatDate(log.created_at)}</td>
                  <td>
                    {log.user.fname} {log.user.lname}
                  </td>
                  <td>{log.description}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
