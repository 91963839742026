import React, { useState } from "react";
import { login } from "../api";
import { useHistory, Link } from "react-router-dom";
import logo from "../assets/logo-color.png";

export default () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { push } = useHistory();

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("email", email);
    data.append("password", password);

    login(data)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user_id", res.data.user.id);
        push("/studenti");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="login">
      <img src={logo} alt="IT Centar" />
      <form onSubmit={onSubmit}>
        <label>Email</label>
        <input type="email" onChange={(e) => setEmail(e.target.value)} />
        <label>Lozinka</label>
        <input type="password" onChange={(e) => setPassword(e.target.value)} />
        <button className="btn blue" type="submit">
          Prijavi se
        </button>
        <div style={{ textAlign: "right" }}>
          <Link to="/resetovanje-lozinke">
            <small>Zaboravili ste lozinku?</small>
          </Link>
        </div>
      </form>
    </div>
  );
};
