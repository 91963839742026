import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://kursevi.itcentar.rs/",
  // baseURL: "http://localhost:8000/",
});

axiosInstance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const login = (data) => axiosInstance.post("login", data);

export const getCategoryList = () => axiosInstance.get("categories");
export const createCategory = (data) => axiosInstance.post("categories", data);
export const editCategory = (data, id) =>
  axiosInstance.post("categories/" + id, data);
export const deleteCategory = (id) => axiosInstance.delete("categories/" + id);

export const getActiveList = (query) =>
  axiosInstance.get("active-courses?" + query);
export const getFutureList = (query) =>
  axiosInstance.get("future-courses?" + query);
export const getArchivedList = (query) =>
  axiosInstance.get("archived-courses?" + query);
export const getFinishedList = (query) =>
  axiosInstance.get("finished-courses?" + query);

export const createCourse = (data) => axiosInstance.post("courses", data);
export const editCourse = (data, id) =>
  axiosInstance.post("courses/" + id, data);
export const deleteCourse = (id) => axiosInstance.delete("courses/" + id);
export const getCourse = (id) => axiosInstance.get("courses/" + id);
export const archiveCourse = (data, id) =>
  axiosInstance.post("course/" + id + "/archive", data);

export const getStudentList = () => axiosInstance.get("students");
export const getStudent = (id) => axiosInstance.get("students/" + id);
export const createStudent = (data) => axiosInstance.post("students", data);
export const editStudent = (data, id) =>
  axiosInstance.post("students/" + id, data);
export const deleteStudent = (id) => axiosInstance.delete("students/" + id);

export const editContract = (data) =>
  axiosInstance.post("contract-status", data);
export const interested = (data) =>
  axiosInstance.post("student-course-category", data);
export const getRate = () => axiosInstance.get("kursna_lista");
export const sign = (data) => axiosInstance.post("student-course", data);
export const makePayment = (data) =>
  axiosInstance.post("student-payment", data);
export const updatePayment = (id, data) =>
  axiosInstance.post(`student-payment/${id}/edit`, data);

export const getAccountList = () => axiosInstance.get("accounts");
export const createAccount = (data) => axiosInstance.post("account", data);
export const editAccount = (data, id) =>
  axiosInstance.post("account/" + id, data);
export const deleteAccount = (id) => axiosInstance.delete("account/" + id);

export const getLogList = () => axiosInstance.get("logs");

// documents
export const documentExists = (id) =>
  axiosInstance.get(`course/${id}/documents/exist`);
export const documentDownload = (id) =>
  axiosInstance.get(`course/${id}/documents/download`);
export const documentGenerate = (id, query, data) =>
  axiosInstance.post(`course/${id}/documents/generate${query}`, data);

// interested
export const getActiveAndFutureCourses = () =>
  axiosInstance.get("active-future-courses");
export const getInterestedStudents = (id) =>
  axiosInstance.get(`course/${id}/interested-students`);
// GET /student-course/{studentId}/{courseId}/delete
export const removeStudentFromCourse = (studentId, courseId) =>
  axiosInstance.get(`student-course/${studentId}/${courseId}/delete`);
