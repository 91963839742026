export default function formatDate(date) {
  if (date === null) return null;
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  var monthNames = [
    "jan",
    "feb",
    "mar",
    "apr",
    "maj",
    "jun",
    "jul",
    "avg",
    "sep",
    "okt",
    "nov",
    "dec",
  ];
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  return `${day}. ${monthNames[monthIndex]} ${year}.`;
}
