import React, { useState, useEffect } from "react";
import { getCategoryList, getFinishedList } from "../api";
import { Link, useLocation, useHistory } from "react-router-dom";
import formatDate from "../helpers/formatDate";

export default () => {
  const location = useLocation();
  const history = useHistory();

  const [list, setList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [courseName, setCourseName] = useState("");

  const [sort, setSort] = useState("start_date/asc");
  const [sortBy, setSortBy] = useState("start_date");
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    // get sort params from url
    const params = new URLSearchParams(location.search);
    const sortBy = params.get("sortBy");
    const order = params.get("order");
    if (sortBy) setSortBy(sortBy);
    if (order) setOrder(order);
    if (sortBy && order) setSort(`${sortBy}/${order}`);
  }, [location.search]);

  const handleSort = (e) => {
    setSort(e.target.value);
    setSortBy(e.target.value.split("/")[0]);
    setOrder(e.target.value.split("/")[1]);
    // update url
    const params = new URLSearchParams(location.search);
    params.set("sortBy", e.target.value.split("/")[0]);
    params.set("order", e.target.value.split("/")[1]);

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const getList = () => {
    let query = "";

    if (sortBy && order) {
      query = `sortBy=${sortBy}&order=${order}`;
    }

    console.log(query);

    getFinishedList(query)
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();
  }, [sortBy, order]);

  useEffect(() => {
    if (Array.isArray(list) && list.length > 0)
      getCategoryList()
        .then((res) => {
          setCategoryList(res.data);
        })
        .catch((err) => console.log(err));
  }, [list]);

  const resetFilters = () => {
    setSelectedCategory("");
    setCourseName("");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // marginTop: 16,
        padding: 16,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: 16,
        }}
      >
        {/* sorter as select element */}
        <span
          style={{
            marginBottom: 8,
          }}
        >
          Sortiranje
        </span>
        <select className="category-select" value={sort} onChange={handleSort}>
          <option value="start_date/asc">Po datumu početka (Uzlazno)</option>
          <option value="start_date/desc">Po datumu početka (Silazno)</option>
          <option value="end_date/asc">Po datumu završetka (Uzlazno)</option>
          <option value="end_date/desc">Po datumu završetka (Silazno)</option>
        </select>

        <span style={{ height: 16 }} />

        <input
          value={courseName}
          onChange={(e) => setCourseName(e.target.value)}
          placeholder="Naziv kursa"
        />
        <span style={{ height: 8 }} />
        <select
          className="category-select"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="">Sve kategorije</option>
          {categoryList
            .filter((category) =>
              list.some((course) => category.id === course.category_id)
            )
            .map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
        </select>
        <span style={{ height: 8 }} />
        {(selectedCategory || courseName) && (
          <button className="btn gray" onClick={resetFilters}>
            Resetuj filtere
          </button>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <table>
          <thead>
            <tr>
              <td></td>
              <th>Naziv kursa</th>
              <th className="center">Datum početka</th>
              <th className="center">Datum završetka</th>
              <th className="center">Ostvareni profit</th>
              <th className="center">Predviđeni profit</th>
              <th className="center">Upisano</th>
              <th style={{ textAlign: "right" }}>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {list
              .filter((course) =>
                selectedCategory
                  ? course.category_id.toString() === selectedCategory
                  : true && courseName
                  ? course.name.toLowerCase().includes(courseName.toLowerCase())
                  : true
              )
              .map((course, i) => (
                <tr key={course.id}>
                  <td>{i + 1}.</td>
                  <td>{course.name}</td>
                  <td className="center">{formatDate(course.start_date)}</td>
                  <td className="center">{formatDate(course.end_date)}</td>
                  <td className="center">
                    {parseFloat(course.realized_profit).toFixed(0)}
                  </td>
                  <td className="center">
                    {parseFloat(course.expected_profit).toFixed(0)}
                  </td>
                  <td className="center">{course.student.length}</td>
                  <td style={{ textAlign: "right" }}>
                    <Link
                      className="btn blue"
                      to={"/kursevi/" + course.id + "/završen"}
                    >
                      Pregled
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
