import React, { useEffect, useState } from "react";
import Modal from "react-modal/lib/components/Modal";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  getActiveAndFutureCourses,
  getInterestedStudents,
  getStudentList,
  interested,
  removeStudentFromCourse,
} from "../api";
import formatDate from "../helpers/formatDate";

const Interested = () => {
  const location = useLocation();
  const history = useHistory();

  const [list, setList] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");

  const [allStudents, setAllStudents] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [search, setSearch] = useState("");

  const getList = () => {
    getActiveAndFutureCourses()
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();

    // get selected course from url
    const params = new URLSearchParams(location.search);
    const course = params.get("kurs");
    // parse to number
    if (course) setSelectedCourse(parseInt(course));
  }, [location.search]);

  useEffect(() => {
    if (!selectedCourse) return;
    getInterestedStudents(selectedCourse)
      .then((res) => {
        setStudents(res.data);
      })
      .catch((err) => console.log(err));

    // set location
    history.push({
      pathname: `/zainteresovani?kurs=${selectedCourse}`,
    });
  }, [selectedCourse]);

  useEffect(() => {
    getStudentList()
      .then((res) => {
        setAllStudents(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const add = (student_id) => {
    interested({
      student_id,
      course_id: selectedCourse,
    })
      .then((res) => {
        setAddModal(false);
        getInterestedStudents(selectedCourse)
          .then((res) => {
            setStudents(res.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const remove = (studentId) => {
    removeStudentFromCourse(studentId, selectedCourse)
      .then((res) => {
        getInterestedStudents(selectedCourse)
          .then((res) => {
            setStudents(res.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="interested-container">
      <ol className="list">
        {list.map((item) => (
          <li
            key={item.id}
            className={`list-item ${
              selectedCourse === item.id ? "list-item-active" : ""
            }`}
          >
            {/* expand */}
            <button
              className="list-item-button"
              onClick={() => setSelectedCourse(item.id)}
            >
              <span>{item.name}</span>
              <span className="arrow">{">"}</span>
            </button>
          </li>
        ))}
      </ol>
      <div className="students">
        {selectedCourse ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <button className="btn blue lg" onClick={() => setAddModal(true)}>
                Dodaj studenta
              </button>
            </div>
            <table>
              <caption>Zainteresovani studenti</caption>
              <thead>
                <tr>
                  <th></th>
                  <th>Ime i prezime</th>
                  <th>Email</th>
                  <th>Broj telefona</th>
                  <th>Napomena</th>
                  <th
                    style={{
                      textAlign: "right",
                    }}
                  >
                    Akcije
                  </th>
                </tr>
              </thead>
              <tbody>
                {students.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>
                      {item.fname} {item.lname}
                    </td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.note}</td>
                    <td style={{ textAlign: "right" }}>
                      <Link
                        to={"/studenti/" + item.id + "/" + selectedCourse}
                        className="btn green"
                      >
                        Upiši
                      </Link>
                      {/* remove */}
                      <button
                        className="btn gray"
                        onClick={() => remove(item.id)}
                      >
                        Ukloni
                      </button>

                      <Link to={`/studenti/${item.id}`}>
                        <button className="btn blue">Detalji</button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>Odaberite neki kurs</p>
        )}
      </div>

      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={addModal}
      >
        <div className="modal">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Pretraži"
            style={{ width: "100%", marginBottom: 16 }}
          />
          <div
            style={{
              maxHeight: 500,
              width: 1000,
              overflow: "auto",
            }}
          >
            <table>
              <thead>
                <tr>
                  <th>Ime i prezime</th>
                  <th>Email</th>
                  <th>Telefon</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allStudents.length > 0 &&
                  allStudents
                    .filter(
                      (student) =>
                        (
                          student.fname.toLowerCase() +
                          student.lname.toLowerCase()
                        ).includes(search.toLowerCase()) &&
                        !students.some((s) => s.id === student.id)
                    )
                    .map((student) => (
                      <tr key={student.id}>
                        <td>
                          {student.fname} {student.lname}
                        </td>
                        <td>{student.email}</td>
                        <td>{student.phone}</td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <button
                            className="btn blue"
                            onClick={() => add(student.id)}
                          >
                            Dodaj
                          </button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              marginTop: 16,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn gray"
              type="button"
              onClick={() => setAddModal(false)}
            >
              Odustani
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Interested;
