import React, { useState, useEffect } from "react";
import { getCategoryList, getFutureList, createCourse } from "../api";
import { Link, useLocation } from "react-router-dom";
import Modal from "react-modal";

export default () => {
  const location = useLocation();

  const [list, setList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [courseName, setCourseName] = useState("");

  const [createModal, setCreateModal] = useState(false);

  const [sort, setSort] = useState("date_start/asc");
  const [sortBy, setSortBy] = useState("date_start");
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    // get sort params from url
    const params = new URLSearchParams(location.search);
    const sortBy = params.get("sortBy");
    const order = params.get("order");
    if (sortBy) setSortBy(sortBy);
    if (order) setOrder(order);
    if (sortBy && order) setSort(`${sortBy}/${order}`);
  }, [location.search]);

  const handleSort = (e) => {
    setSort(e.target.value);
    setSortBy(e.target.value.split("/")[0]);
    setOrder(e.target.value.split("/")[1]);
    // update url
    const params = new URLSearchParams(location.search);
    params.set("sortBy", e.target.value.split("/")[0]);
    params.set("order", e.target.value.split("/")[1]);
    window.history.pushState(
      null,
      null,
      `#/kursevi-budući?${params.toString()}`
    );

    // fetch data
    getList();
  };

  const getList = () => {
    // get sortBy and order from url
    const params = new URLSearchParams(location.search);
    const sortBy = params.get("sortBy");
    const order = params.get("order");
    const query = `sortBy=${sortBy}&order=${order}`;

    getFutureList(query)
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    // if (Array.isArray(list) && list.length > 0)
    getCategoryList()
      .then((res) => {
        setCategoryList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const resetFilters = () => {
    setSelectedCategory("");
    setCourseName("");
  };

  const [cname, setcname] = useState("");
  const [cprice, setcprice] = useState("");
  const [cpriceperclass, setcpriceperclass] = useState("");
  const [cpayments, setcpayments] = useState("");
  const [cclasses, setcclasses] = useState("");
  const [ccategory, setccategory] = useState("");
  const [cstartdate, setcstartdate] = useState("");
  const [cenddate, setcenddate] = useState("");

  const create = () => {
    let data = new FormData();
    data.append("name", cname);
    data.append("price", cprice);
    data.append("price_per_class", cpriceperclass);
    data.append("payments", cpayments);
    data.append("classes", cclasses);
    data.append("category_id", ccategory);
    data.append("start_date", cstartdate);
    data.append("end_date", cenddate);
    createCourse(data)
      .then(() => setCreateModal(false))
      .then(() => getList())
      .then(() => {
        setcname("");
        setccategory("");
        setcclasses("");
        setcprice("");
        setcpayments("");
        setcpriceperclass("");
        setcstartdate("");
        setcenddate("");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (ccategory) {
      const selected = categoryList.find(
        (category) => parseInt(ccategory) === category.id
      );
      if (selected) {
        setcprice(parseInt(selected.price));
        setcpriceperclass(selected.price_per_class);
        setcpayments(selected.payments);
        setcclasses(selected.classes);
      }
    }
  }, [ccategory, categoryList]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // marginTop: 16,
        padding: 16,
      }}
    >
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={createModal}
      >
        <div className="modal">
          <form onSubmit={create}>
            <label>Naziv kursa</label>
            <input
              required
              value={cname}
              onChange={(e) => setcname(e.target.value)}
            />
            <label>Kategorija</label>
            <select
              required
              value={ccategory}
              onChange={(e) => setccategory(e.target.value)}
            >
              <option hidden>Odaberi kategoriju</option>
              {categoryList.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            <label>Ukupna cena</label>
            <input
              required
              type="number"
              value={cprice}
              onChange={(e) => setcprice(e.target.value)}
            />
            <label>Broj rata</label>
            <input
              required
              type="number"
              value={cpayments}
              onChange={(e) => setcpayments(e.target.value)}
            />
            <label>Broj časova</label>
            <input
              required
              type="number"
              value={cclasses}
              onChange={(e) => setcclasses(e.target.value)}
            />
            <label>Cena profesora</label>
            <input
              required
              type="number"
              value={cpriceperclass}
              onChange={(e) => setcpriceperclass(e.target.value)}
            />
            <label>Datum početka</label>
            <input
              required
              type="date"
              value={cstartdate}
              onChange={(e) => setcstartdate(e.target.value)}
            />
            <label>Datum završetka</label>
            <input
              required
              type="date"
              value={cenddate}
              onChange={(e) => setcenddate(e.target.value)}
            />
            <div>
              <button
                className="btn gray"
                type="button"
                onClick={() => setCreateModal(false)}
              >
                Odustani
              </button>
              &nbsp;
              <button className="btn blue" type="submit">
                Sačuvaj
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: 16,
        }}
      >
        {/* sorter as select element */}
        {/* <span
          style={{
            marginBottom: 8,
          }}
        >
          Sortiranje
        </span>
        <select className="category-select" value={sort} onChange={handleSort}>
          <option value="start_date/asc">Po datumu početka (Uzlazno)</option>
          <option value="start_date/desc">Po datumu početka (Silazno)</option>
          <option value="end_date/asc">Po datumu završetka (Uzlazno)</option>
          <option value="end_date/desc">Po datumu završetka (Silazno)</option>
        </select>

        <span style={{ height: 16 }} /> */}

        <input
          value={courseName}
          onChange={(e) => setCourseName(e.target.value)}
          placeholder="Naziv kursa"
        />
        <span style={{ height: 8 }} />
        <select
          className="category-select"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="">Sve kategorije</option>
          {categoryList
            .filter((category) =>
              list.some((course) => category.id === course.category_id)
            )
            .map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
        </select>
        <span style={{ height: 8 }} />
        {(selectedCategory || courseName) && (
          <button className="btn gray" onClick={resetFilters}>
            Resetuj filtere
          </button>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <button
          style={{
            alignSelf: "flex-end",
          }}
          onClick={() => setCreateModal(true)}
          className="btn blue lg"
        >
          Dodaj kurs
        </button>
        <table>
          <thead>
            <tr>
              <td></td>
              <th>Naziv kursa</th>
              <th className="center">Broj časova</th>
              <th className="center">Cena</th>
              <th className="center">Broj rata</th>
              <th className="center">Cena profesora</th>
              <th className="center">Ostvareni profit</th>
              <th className="center">Predviđeni profit</th>
              <th className="center">Upisano</th>
              <th style={{ textAlign: "right" }}>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {list
              .filter((course) =>
                selectedCategory
                  ? course.category_id.toString() === selectedCategory
                  : true && courseName
                  ? course.name.toLowerCase().includes(courseName.toLowerCase())
                  : true
              )
              .map((course, i) => (
                <tr key={course.id}>
                  <td>{i + 1}.</td>
                  <td>{course.name}</td>
                  <td className="center">{course.classes}</td>
                  <td className="center">{course.price}</td>
                  <td className="center">{course.payments}</td>
                  <td className="center">{course.price_per_class}</td>
                  <td className="center">
                    {parseFloat(course.realized_profit).toFixed(0)}
                  </td>
                  <td className="center">
                    {parseFloat(course.expected_profit).toFixed(0)}
                  </td>
                  <td className="center">{course.student.length}</td>
                  <td style={{ textAlign: "right" }}>
                    <Link
                      className="btn blue"
                      to={"/kursevi/" + course.id + "/budući"}
                    >
                      Pregled
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
