import React from "react";
import { NavLink, useHistory, Link } from "react-router-dom";
import logo from "../assets/favicon.png";

export default () => {
  const { push } = useHistory();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    push("/");
  };

  return (
    <header>
      <Link to="/studenti">
        <img src={logo} alt="IT Centar" />
      </Link>
      <div>
        <NavLink activeClassName="active" to="/studenti">
          Studenti
        </NavLink>
        <NavLink activeClassName="active" to="/zainteresovani">
          Zainteresovani
        </NavLink>
        <NavLink activeClassName="active" to="/kategorije">
          Kategorije
        </NavLink>
        <NavLink activeClassName="active" to="/kursevi-budući">
          Budući kursevi
        </NavLink>
        <NavLink activeClassName="active" to="/kursevi-aktivni">
          Aktivni kursevi
        </NavLink>
        <NavLink activeClassName="active" to="/kursevi-završeni">
          Završeni kursevi
        </NavLink>
        <NavLink activeClassName="active" to="/kursevi-arhivirani">
          Arhivirani kursevi
        </NavLink>
        <NavLink activeClassName="active" to="/nalozi">
          Nalozi
        </NavLink>
        <NavLink activeClassName="active" to="/zapisi">
          Zapisi
        </NavLink>
      </div>
      <button className="btn gray" onClick={logout}>
        Odjavi se
      </button>
    </header>
  );
};
