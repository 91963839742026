import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import PasswordReset from "./views/PasswordReset";
import Login from "./views/Login";
import ActiveList from "./views/ActiveList";
import FutureList from "./views/FutureList";
import FinishedList from "./views/FinishedList";
import ArchivedList from "./views/ArchivedList";
import CategoryList from "./views/CategoryList";
import AccountList from "./views/AccountList";
import StudentList from "./views/StudentList.js";
import StudentDetails from "./views/StudentDetails";
import LogList from "./views/LogList";
import Header from "./components/Header";
import StudentCourse from "./views/StudentCourse";
import CourseDetails from "./views/CourseDetails";
import Interested from "./views/Interested";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/resetovanje-lozinke" component={PasswordReset} />
          <Route exact path="/" component={Login} />
          <Route path="">
            <Header />

            <section>
              <Route exact path="/kursevi-aktivni" component={ActiveList} />
              <Route exact path="/kursevi-budući" component={FutureList} />
              <Route exact path="/kursevi-završeni" component={FinishedList} />
              <Route
                exact
                path="/kursevi-arhivirani"
                component={ArchivedList}
              />

              <Route path="/kursevi/:id/:status" component={CourseDetails} />

              <Route exact path="/kategorije" component={CategoryList} />

              <Route exact path="/nalozi" component={AccountList} />
              <Route exact path="/studenti" component={StudentList} />
              <Route exact path="/studenti/:id" component={StudentDetails} />

              <Route
                exact
                path="/studenti/:student_id/:course_id"
                component={StudentCourse}
              />

              <Route exact path="/zainteresovani*" component={Interested} />

              {/* <Route exact path="/profesori" component={Professors} /> */}
              {/* <Route path="/profesori/:id" component={Professor} /> */}

              <Route exact path="/zapisi" component={LogList} />
            </section>
          </Route>
        </Switch>
      </Router>
      <footer>&copy; IT Centar 2020</footer>
      <ToastContainer autoClose={false} position="bottom-right" />
    </div>
  );
}

export default App;
