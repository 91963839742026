import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { deleteStudent, getStudent, editStudent, sign } from "../api";
import Modal from "react-modal";

const Course = ({ course, i, student_id }) => {
  const [note, setNote] = useState(course.pivot.note || "");

  const edit = () => {
    let data = new FormData();
    data.append("_method", "put");
    data.append("note", note);
    data.append("student_id", student_id);
    data.append("course_id", course.id);
    sign(data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  return (
    <tr key={course.id}>
      <td>{i + 1}.</td>
      <td>{course.name}</td>
      <td className="center">{course.pivot.discount}</td>
      <td className="center">{course.pivot.price}</td>
      <td className="center">
        {course.pivot.contract_status ? (
          <span>&#10003;</span>
        ) : (
          <span>&#xD7;</span>
        )}
      </td>
      <td>
        <form onSubmit={edit}>
          <input
            onBlur={edit}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </form>
      </td>
      <td
        style={{
          textAlign: "right",
        }}
      >
        <Link
          className="btn green"
          to={"/studenti/" + student_id + "/" + course.id}
        >
          Plaćanja i ugovor
        </Link>
      </td>
    </tr>
  );
};

const Interested = ({ student_id, i, course }) => {
  // console.log(course);
  const [note, setNote] = useState(course.pivot.note || "");

  const edit = () => {
    let data = new FormData();
    data.append("_method", "put");
    data.append("note", note);
    data.append("student_id", student_id);
    data.append("course_id", course.id);
    sign(data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  return (
    <tr>
      <td>{i + 1}.</td>
      <td>{course.name}</td>
      <td>
        <form onSubmit={edit}>
          <input
            onBlur={edit}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </form>
      </td>
      <td
        style={{
          textAlign: "right",
        }}
      >
        <Link
          className="btn green"
          to={"/studenti/" + student_id + "/" + course.id}
        >
          Plaćanja i ugovor
        </Link>
      </td>
    </tr>
  );
};

export default () => {
  const { id } = useParams();
  const [student, setStudent] = useState({});

  useEffect(() => {
    getStudent(id)
      .then((res) => {
        const {
          fname,
          lname,
          address,
          email,
          phone,
          id_no,
          heard_from,
        } = res.data;
        setStudent(res.data);
        setcfname(fname);
        setclname(lname);
        setcaddress(address);
        setcemail(email);
        setcphone(phone);
        setcid(id_no);
        setcheard(heard_from);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const [cfname, setcfname] = useState("");
  const [clname, setclname] = useState("");
  const [caddress, setcaddress] = useState("");
  const [cemail, setcemail] = useState("");
  const [cphone, setcphone] = useState("");
  const [cid, setcid] = useState("");
  const [cheard, setcheard] = useState("");

  const [didChange, setDidChange] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const { goBack } = useHistory();

  const _delete = () => {
    deleteStudent(id)
      .then(() => goBack())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setDidChange(true);
  }, [cfname, clname, caddress, cemail, cid, cphone, cheard]);

  const edit = () => {
    if (didChange) {
      let data = new FormData();
      data.append("_method", "put");
      data.append("fname", cfname);
      data.append("lname", clname);
      data.append("email", cemail);
      data.append("phone", cphone);
      data.append("id_no", cid);
      data.append("address", caddress);
      data.append("heard_from", cheard);
      editStudent(data, id)
        .then((res) => {
          setDidChange(false);
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={deleteModal}
      >
        <div className="modal">
          <form onSubmit={_delete}>
            <p>
              Sigurno želite da obrišete studenta {cfname} {clname}?
            </p>
            <div>
              <button
                className="btn gray"
                type="button"
                onClick={() => setDeleteModal(false)}
              >
                Odustani
              </button>
              &nbsp;
              <button className="btn red" type="submit">
                Obriši
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <div className="details-section-one">
        <form onSubmit={edit} className="details-form">
          <div>
            <label>Ime</label>
            <input
              required
              value={cfname}
              onChange={(e) => setcfname(e.target.value)}
              onBlur={edit}
            />
          </div>
          <div>
            <label>Prezime</label>
            <input
              required
              value={clname}
              onChange={(e) => setclname(e.target.value)}
              onBlur={edit}
            />
          </div>
          <div>
            <label>Email</label>
            <input
              required
              value={cemail}
              onChange={(e) => setcemail(e.target.value)}
              onBlur={edit}
            />
          </div>
          <div>
            <label>Telefon</label>
            <input
              required
              value={cphone}
              onChange={(e) => setcphone(e.target.value)}
              onBlur={edit}
            />
          </div>
          <div>
            <label>Adresa</label>
            <input
              required
              value={caddress}
              onChange={(e) => setcaddress(e.target.value)}
              onBlur={edit}
            />
          </div>
          <div>
            <label>Lična karta</label>
            <input
              required
              type="number"
              value={cid}
              onChange={(e) => setcid(e.target.value)}
              onBlur={edit}
            />
          </div>
          <div>
            <label>Kako je čuo</label>
            <select
              onBlur={edit}
              onChange={(e) => setcheard(e.target.value)}
              value={cheard}
            >
              <option value="friend">Prijatelj</option>
              <option value="friend_student">Prijatelj polaznik</option>
              <option value="ad">Reklama</option>
              <option value="job">Posao</option>
              <option value="google">Google</option>
              <option value="social_media">Društvene mreze</option>
              <option value="event">Događaj</option>
            </select>
          </div>
          <input type="submit" hidden />
        </form>
        <form onSubmit={() => setDeleteModal(true)}>
          <button className="btn red">Obriši studenta</button>
        </form>
      </div>
      <div style={{ display: "flex" }}>
        <table className="table">
          <caption>Aktivni kursevi</caption>
          <thead>
            <tr>
              <th></th>
              <th>Naziv</th>
              <th className="center">Popust</th>
              <th className="center">Cena</th>
              <th className="center">Ugovor</th>
              <th>Napomena</th>
              <th
                style={{
                  textAlign: "right",
                }}
              >
                Akcije
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(student).length > 0 &&
              Array.isArray(student.active_course) &&
              student.active_course.map((course, i) => (
                <Course
                  key={student.id}
                  course={course}
                  i={i}
                  student_id={student.id}
                />
              ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: "flex" }}>
        <table className="table">
          <caption>Završeni kursevi</caption>
          <thead>
            <tr>
              <th></th>
              <th>Naziv</th>
              <th className="center">Popust</th>
              <th className="center">Cena</th>
              <th className="center">Ugovor</th>
              <th>Napomena</th>
              <th
                style={{
                  textAlign: "right",
                }}
              >
                Akcije
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(student).length > 0 &&
              Array.isArray(student.finished_course) &&
              student.finished_course.map((course, i) => (
                <Course
                  key={student.id}
                  course={course}
                  i={i}
                  student_id={student.id}
                />
              ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: "flex" }}>
        <table className="table">
          <caption>Zainteresovan za kurseve</caption>
          <thead>
            <tr>
              <th></th>
              <th>Naziv</th>
              <th>Napomena</th>
              <th
                style={{
                  textAlign: "right",
                }}
              >
                Akcije
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(student).length > 0 &&
              Array.isArray(student.category) &&
              student.category.map((course, i) => (
                <Interested
                  key={student.id}
                  course={course}
                  i={i}
                  student_id={student.id}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
