import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import {
  getCourse,
  getCategoryList,
  editCourse,
  deleteCourse,
  getStudentList,
  interested,
  archiveCourse,
  sign,
  documentExists,
  documentGenerate,
  documentDownload,
} from "../api";
import Modal from "react-modal";
import formatDate from "../helpers/formatDate";

const Student = ({ student, i, course_id }) => {
  const [note, setNote] = useState(student.pivot.note || "");
  const [paid, setPaid] = useState("");

  useEffect(() => {
    const { payment } = student;
    if (Array.isArray(payment) && payment.length > 0) {
      const hasPaid = payment
        .filter((el) => el.course_id === course_id)
        .map((el) => el.pivot)
        .reduce((acc, val) => acc + val.amount, 0);
      setPaid(parseInt(hasPaid, 10));
    }
  }, [student, course_id]);

  const edit = () => {
    let data = new FormData();
    data.append("_method", "put");
    data.append("note", note);
    data.append("student_id", student.id);
    data.append("course_id", course_id);
    sign(data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  return (
    <tr key={student.id}>
      <td>{i + 1}.</td>
      <td>
        <a href={`#/studenti/${student.id}`}>
          {student.fname} {student.lname}
        </a>
      </td>
      <td className="center">{student.pivot.discount}</td>
      <td className="center">{student.pivot.price}</td>
      <td className="center">{paid}</td>
      <td className="center">{student.pivot.price - paid}</td>
      <td className="center">
        {student.pivot.contract_status ? (
          <span>&#10003;</span>
        ) : (
          <span>&#xD7;</span>
        )}
      </td>
      <td>
        <form onSubmit={edit}>
          <input
            onBlur={edit}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </form>
      </td>
      <td
        style={{
          textAlign: "right",
        }}
      >
        <Link
          className="btn green"
          to={"/studenti/" + student.id + "/" + course_id}
        >
          Plaćanja i ugovor
        </Link>
        <Link className="btn blue" to={"/studenti/" + student.id}>
          Detalji o studentu
        </Link>
      </td>
    </tr>
  );
};

const StudentInterested = ({ student, i, course_id }) => {
  const [note, setNote] = useState(student.pivot.note || "");

  const edit = () => {
    let data = new FormData();
    data.append("_method", "put");
    data.append("note", note);
    data.append("student_id", student.id);
    data.append("course_id", course_id);
    sign(data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  return (
    <tr>
      <td>{i + 1}.</td>
      <td>
        {student.fname} {student.lname}
      </td>
      <td>{student.email}</td>
      <td>{student.phone}</td>
      <td>
        <form onSubmit={edit}>
          <input
            onBlur={edit}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </form>
      </td>
      <td
        style={{
          textAlign: "right",
        }}
      >
        <Link
          className="btn green"
          to={"/studenti/" + student.id + "/" + course_id}
        >
          Plaćanja i ugovor
        </Link>
        <Link className="btn blue" to={"/studenti/" + student.id}>
          Detalji o studentu
        </Link>
      </td>
    </tr>
  );
};

export default () => {
  const { id, status } = useParams();
  const [course, setCourse] = useState({});
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    getCourse(id)
      .then((res) => {
        console.log("nooo");
        const {
          name,
          category_id,
          price,
          price_per_class,
          payments,
          classes,
          start_date,
          end_date,
        } = res.data;
        setCourse(res.data);
        setcname(name);
        setccategory(category_id);
        setcprice(price);
        setcpriceperclass(price_per_class);
        setcpayments(payments);
        setcclasses(classes);
        setcstartdate(start_date.toString().substr(0, 10));
        setcenddate(end_date.toString().substr(0, 10));
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    getCategoryList()
      .then((res) => {
        setCategoryList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const [cname, setcname] = useState("");
  const [cprice, setcprice] = useState("");
  const [cpriceperclass, setcpriceperclass] = useState("");
  const [cpayments, setcpayments] = useState("");
  const [cclasses, setcclasses] = useState("");
  const [ccategory, setccategory] = useState("");
  const [cstartdate, setcstartdate] = useState("");
  const [cenddate, setcenddate] = useState("");

  const [didChange, setDidChange] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const { goBack } = useHistory();

  const _delete = () => {
    deleteCourse(id)
      .then(() => goBack())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setDidChange(true);
  }, [
    cname,
    cprice,
    cpriceperclass,
    cpayments,
    cclasses,
    ccategory,
    cstartdate,
    cenddate,
  ]);

  const edit = () => {
    if (didChange) {
      let data = new FormData();
      data.append("_method", "put");
      data.append("name", cname);
      data.append("price", cprice);
      data.append("price_per_class", cpriceperclass);
      data.append("payments", cpayments);
      data.append("classes", cclasses);
      data.append("category_id", ccategory);
      data.append("start_date", cstartdate);
      data.append("end_date", cenddate);
      editCourse(data, id)
        .then((res) => {
          setDidChange(false);
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  };

  const [addModal, setAddModal] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getStudentList()
      .then((res) => setStudentList(res.data))
      .catch((err) => console.log(err));
  }, []);

  const { push } = useHistory();

  const add = (student_id) => {
    interested({
      student_id,
      course_id: course.id,
    })
      .then((res) => {
        push("/studenti/" + student_id + "/" + course.id);
      })
      .catch((err) => console.log(err));
  };

  const [archiveModal, setArchiveModal] = useState(false);

  const [archivedate, setarchivedate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  const archive = () => {
    let data = new FormData();
    data.append("archived_date", archivedate.substr(0, 10));
    archiveCourse(data, course.id)
      .then(() => goBack())
      .catch((err) => console.log(err));
  };

  const [ifDocumentExists, setIfDocumentExists] = useState(false);

  useEffect(() => {
    documentExists(id)
      .then((res) => {
        setIfDocumentExists(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const [generateModal, setGenerateModal] = useState(false);
  const [regenerate, setRegenerate] = useState(false);

  const [addition, setaddition] = useState(false);
  const [voucher, setvoucher] = useState(false);

  const generate_document = (e) => {
    e.preventDefault();
    let data = {
      document_types: [],
    };

    if (addition) {
      data.document_types.push("addition");
    }

    if (voucher) {
      data.document_types.push("voucher");
    }

    documentGenerate(course.id, regenerate ? "?regenerate=true" : "", data)
      .then((res) => {
        setGenerateModal(false);
        setRegenerate(false);
        documentExists(course.id)
          .then((res) => setIfDocumentExists(res.data))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const download_document = () => {
    documentDownload(course.id)
      .then((res) => {
        window.open(res.data.link);
      })
      .catch((err) => console.log(err));
  };

  // test

  return (
    <div>
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={deleteModal}
      >
        <div className="modal">
          <form onSubmit={_delete}>
            <p>Sigurno želite da obrišete kurs {cname}?</p>
            <div>
              <button
                className="btn gray"
                type="button"
                onClick={() => setDeleteModal(false)}
              >
                Odustani
              </button>
              &nbsp;
              <button className="btn red" type="submit">
                Obriši
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={addModal}
      >
        <div className="modal">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Pretraži"
            style={{ width: "100%", marginBottom: 16 }}
          />
          <div
            style={{
              maxHeight: 500,
              width: 1000,
              overflow: "auto",
            }}
          >
            <table>
              <thead>
                <tr>
                  <th>Ime i prezime</th>
                  <th>Email</th>
                  <th>Telefon</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(course).length > 0 &&
                  Array.isArray(course.student) &&
                  studentList
                    .filter(
                      (student) =>
                        (
                          student.fname.toLowerCase() +
                          student.lname.toLowerCase()
                        ).includes(search.toLowerCase()) &&
                        !course.student.some((s) => s.id === student.id) &&
                        !course.student_interested.some(
                          (s) => s.id === student.id
                        )
                    )
                    .map((student) => (
                      <tr key={student.id}>
                        <td>
                          {student.fname} {student.lname}
                        </td>
                        <td>{student.email}</td>
                        <td>{student.phone}</td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <button
                            className="btn blue"
                            onClick={() => add(student.id)}
                          >
                            Dodaj
                          </button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              marginTop: 16,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn gray"
              type="button"
              onClick={() => setAddModal(false)}
            >
              Odustani
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={archiveModal}
      >
        <div className="modal">
          <form onSubmit={archive}>
            <label>Datum arhiviranja</label>
            <input
              required
              type="date"
              value={archivedate}
              onChange={setarchivedate}
            />
            <div>
              <button
                className="btn gray"
                type="button"
                onClick={() => setArchiveModal(false)}
              >
                Odustani
              </button>
              &nbsp;
              <button className="btn blue" type="submit">
                Arhiviraj
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={generateModal}
      >
        <div className="modal">
          <form onSubmit={generate_document}>
            {/* checkboxes for addition and vouchers */}
            <div
              style={{
                justifyContent: "flex-start",
              }}
            >
              <input
                type="checkbox"
                checked={addition}
                onChange={() => setaddition(!addition)}
                id="addition"
              />
              <label htmlFor="addition">Dodatak</label>
            </div>

            <div
              style={{
                justifyContent: "flex-start",
              }}
            >
              <input
                type="checkbox"
                checked={voucher}
                onChange={() => setvoucher(!voucher)}
                id="voucher"
              />
              <label htmlFor="voucher">Vaučer</label>
            </div>

            <div>
              <button
                className="btn gray"
                type="button"
                onClick={() => setGenerateModal(false)}
              >
                Odustani
              </button>
              &nbsp;
              <button className="btn blue" type="submit">
                Generiši
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <div className="details-section-one">
        <form onSubmit={edit} className="details-form">
          <div>
            <label>Status</label>
            <label>{status}</label>
          </div>
          <div>
            <label>Naziv kursa</label>
            <input
              required
              value={cname}
              onChange={(e) => setcname(e.target.value)}
              onBlur={edit}
            />
          </div>
          <div>
            <label>Kategorija</label>
            <select
              required
              value={ccategory}
              onChange={(e) => setccategory(e.target.value)}
              onBlur={edit}
            >
              <option hidden>Odaberi kategoriju</option>
              {categoryList.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Ukupna cena</label>
            <input
              required
              type="number"
              value={cprice}
              onChange={(e) => setcprice(e.target.value)}
              onBlur={edit}
            />
          </div>
          <div>
            <label>Broj rata</label>
            <input
              required
              type="number"
              value={cpayments}
              onChange={(e) => setcpayments(e.target.value)}
              onBlur={edit}
            />
          </div>
          <div>
            <label>Broj časova</label>
            <input
              required
              type="number"
              value={cclasses}
              onChange={(e) => setcclasses(e.target.value)}
              onBlur={edit}
            />
          </div>
          <div>
            <label>Cena profesora</label>
            <input
              required
              type="number"
              value={cpriceperclass}
              onChange={(e) => setcpriceperclass(e.target.value)}
              onBlur={edit}
            />
          </div>
          <div>
            <label>Datum početka</label>
            <input
              required
              type="date"
              value={cstartdate}
              onChange={(e) => setcstartdate(e.target.value)}
              onBlur={edit}
            />
          </div>
          <div>
            <label>Datum završetka</label>
            <input
              required
              type="date"
              value={cenddate}
              onChange={(e) => setcenddate(e.target.value)}
              onBlur={edit}
            />
          </div>
          {course.archived_date && (
            <div>
              <label>Datum arhiviranja</label>
              <label>{formatDate(course.archived_date)}</label>
            </div>
          )}
          <div>
            <label>Ostvareni profit</label>
            <label>{parseFloat(course.realized_profit).toFixed(2)}</label>
          </div>
          <div>
            <label>Predviđeni profit</label>
            <label>{parseFloat(course.expected_profit).toFixed(2)}</label>
          </div>
          <input type="submit" hidden />
          {/* <div>
          <button type="button" onClick={() => setCreateModal(false)}>
            Odustani
          </button>
          &nbsp;
          <button type="submit">Sačuvaj</button>
        </div> */}
        </form>
        <div
          style={{
            marginLeft: 12,
          }}
        >
          {status === "budući" && (
            <button className="btn red" onClick={() => setDeleteModal(true)}>
              Obriši kurs
            </button>
          )}
          {status === "završen" && (
            <button className="btn green" onClick={() => setArchiveModal(true)}>
              Arhiviraj kurs
            </button>
          )}
          {/* document */}
          {(status === "arhiviran" ||
            status === "završen" ||
            status === "aktivan") &&
            (ifDocumentExists ? (
              <>
                <button
                  className="btn blue"
                  onClick={() => {
                    setGenerateModal(true);
                    setRegenerate(true);
                  }}
                >
                  Ponovo generiši dokumenta
                </button>
                <button className="btn blue" onClick={download_document}>
                  Preuzmi dokumenta
                </button>
              </>
            ) : (
              // generate document
              <button
                className="btn blue"
                onClick={() => setGenerateModal(true)}
              >
                Generiši dokumenta
              </button>
            ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: 16,
          marginBottom: 0,
        }}
      >
        <button className="btn blue" onClick={() => setAddModal(true)}>
          Dodaj studenta
        </button>
      </div>
      <div style={{ display: "flex" }}>
        <table className="table">
          <caption>Upisani studenti</caption>
          <thead>
            <tr>
              <th></th>
              <th>Ime i prezime</th>
              <th className="center">Popust</th>
              <th className="center">Cena</th>
              <th className="center">Platio</th>
              <th className="center">Preostalo</th>
              <th className="center">Ugovor</th>
              <th>Napomena</th>
              <th
                style={{
                  textAlign: "right",
                }}
              >
                Akcije
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(course).length > 0 &&
              Array.isArray(course.student) &&
              course.student.map((student, i) => (
                <Student
                  key={student.id}
                  student={student}
                  i={i}
                  course_id={course.id}
                />
              ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: "flex" }}>
        <table className="table">
          <caption>Zainteresovani studenti</caption>
          <thead>
            <tr>
              <th></th>
              <th>Ime i prezime</th>
              <th>Email</th>
              <th>Telefon</th>
              <th>Napomena</th>
              <th
                style={{
                  textAlign: "right",
                }}
              >
                Akcije
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(course).length > 0 &&
              Array.isArray(course.student) &&
              course.student_interested.map((student, i) => (
                <StudentInterested
                  key={student.id}
                  student={student}
                  i={i}
                  course_id={course.id}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
