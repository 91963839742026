import React, { useState, useEffect } from "react";
import {
  getAccountList,
  createAccount,
  editAccount,
  deleteAccount,
} from "../api";
import Modal from "react-modal";

export default () => {
  const [list, setList] = useState([]);

  const [studentName, setStudentName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentPhone, setStudentPhone] = useState("");
  // asd
  const getList = () => {
    getAccountList()
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();
  }, []);

  const resetFilters = () => {
    setStudentEmail("");
    setStudentName("");
    setStudentPhone("");
  };

  const [createModal, setCreateModal] = useState(false);

  const [cfname, setcfname] = useState("");
  const [clname, setclname] = useState("");
  const [cemail, setcemail] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [cpasswordconfimation, setcpasswordconfirmation] = useState("");
  const [cphone, setcphone] = useState("");

  const create = () => {
    if (cpassword !== cpasswordconfimation) {
      return;
    }
    let data = new FormData();
    data.append("role", "admin");
    data.append("fname", cfname);
    data.append("lname", clname);
    data.append("email", cemail);
    data.append("phone", cphone);
    data.append("password", cpassword);
    data.append("password_confirmation", cpasswordconfimation);
    createAccount(data)
      .then(() => {
        setCreateModal(false);
        getList();
      })
      .catch((err) => console.log(err));
  };

  const [editModal, setEditModal] = useState(false);

  const [efname, setefname] = useState("");
  const [elname, setelname] = useState("");
  const [eemail, seteemail] = useState("");
  const [ephone, setephone] = useState("");
  const [eid, seteid] = useState("");

  const edit = () => {
    let data = new FormData();
    data.append("role", "admin");
    data.append("fname", efname);
    data.append("lname", elname);
    data.append("email", eemail);
    data.append("phone", ephone);
    editAccount(data, eid)
      .then(() => {
        setEditModal(false);
        getList();
      })
      .catch((err) => console.log(err));
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const [did, setdid] = useState("");
  const [dname, setdname] = useState("");

  const _delete = () => {
    deleteAccount(did)
      .then(() => {
        setDeleteModal(false);
        getList();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // marginTop: 16,
        padding: 16,
      }}
    >
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={createModal}
      >
        <div className="modal">
          <form onSubmit={create}>
            <label>Ime</label>
            <input
              required
              value={cfname}
              onChange={(e) => setcfname(e.target.value)}
            />
            <label>Prezime</label>
            <input
              required
              value={clname}
              onChange={(e) => setclname(e.target.value)}
            />
            <label>Email</label>
            <input
              type="email"
              required
              value={cemail}
              onChange={(e) => setcemail(e.target.value)}
            />
            <label>Telefon</label>
            <input
              required
              value={cphone}
              onChange={(e) => setcphone(e.target.value)}
            />
            <label>Lozinka</label>
            <input
              required
              type="password"
              value={cpassword}
              onChange={(e) => setcpassword(e.target.value)}
            />
            <label>Ponovi lozinku</label>
            <input
              required
              type="password"
              value={cpasswordconfimation}
              onChange={(e) => setcpasswordconfirmation(e.target.value)}
            />
            <div>
              <button
                className="btn gray"
                type="button"
                onClick={() => setCreateModal(false)}
              >
                Odustani
              </button>
              &nbsp;
              <button className="btn blue" type="submit">
                Sačuvaj
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={editModal}
      >
        <div className="modal">
          <form onSubmit={edit}>
            <label>Ime</label>
            <input
              required
              value={efname}
              onChange={(e) => setefname(e.target.value)}
            />
            <label>Prezime</label>
            <input
              required
              value={elname}
              onChange={(e) => setelname(e.target.value)}
            />
            <label>Email</label>
            <input
              type="email"
              required
              value={eemail}
              onChange={(e) => seteemail(e.target.value)}
            />
            <label>Telefon</label>
            <input
              required
              value={ephone}
              onChange={(e) => setephone(e.target.value)}
            />
            <div>
              <button
                className="btn gray"
                type="button"
                onClick={() => setEditModal(false)}
              >
                Odustani
              </button>
              &nbsp;
              <button className="btn blue" type="submit">
                Sačuvaj
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={deleteModal}
      >
        <div className="modal">
          <form onSubmit={_delete}>
            <p>Sigurno želite da obrišete korisnički nalog {dname}?</p>
            <div>
              <button
                className="btn gray"
                type="button"
                onClick={() => setDeleteModal(false)}
              >
                Odustani
              </button>
              &nbsp;
              <button className="btn red" type="submit">
                Obriši
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: 16,
        }}
      >
        <input
          value={studentName}
          onChange={(e) => setStudentName(e.target.value)}
          placeholder="Ime i prezime"
        />
        <span style={{ height: 8 }} />
        <input
          value={studentEmail}
          onChange={(e) => setStudentEmail(e.target.value)}
          placeholder="Email"
        />
        <span style={{ height: 8 }} />
        <input
          value={studentPhone}
          onChange={(e) => setStudentPhone(e.target.value)}
          placeholder="Telefon"
        />
        <span style={{ height: 8 }} />
        <span style={{ height: 8 }} />
        {(studentEmail || studentPhone || studentName) && (
          <button className="btn gray" onClick={resetFilters}>
            Resetuj filtere
          </button>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <button
          style={{
            alignSelf: "flex-end",
          }}
          onClick={() => setCreateModal(true)}
          className="btn blue lg"
        >
          Dodaj korisnički nalog
        </button>
        <table>
          <thead>
            <tr>
              <td></td>
              <th>Ime i prezime</th>
              <th>Email</th>
              <th>Telefon</th>
              <th style={{ textAlign: "right" }}>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {list
              .filter((student) =>
                studentName
                  ? (
                      student.fname.toLowerCase() + student.lname.toLowerCase()
                    ).includes(studentName.toLowerCase())
                  : true && studentEmail && student.email
                  ? student.email
                      .toLowerCase()
                      .includes(studentEmail.toLowerCase())
                  : true && studentPhone && student.phone
                  ? student.phone
                      .toLowerCase()
                      .includes(studentPhone.toLowerCase())
                  : true
              )
              .map((student, i) => (
                <tr key={student.id}>
                  <td>{i + 1}.</td>
                  <td>
                    {student.fname} {student.lname}
                  </td>
                  <td>{student.email}</td>
                  <td>{student.phone}</td>
                  <td style={{ textAlign: "right" }}>
                    <button
                      className="btn green"
                      onClick={() => {
                        setEditModal(true);
                        seteemail(student.email);
                        setephone(student.phone);
                        setefname(student.fname);
                        setelname(student.lname);
                        seteid(student.id);
                      }}
                    >
                      Izmeni
                    </button>
                    <button
                      onClick={() => {
                        setDeleteModal(true);
                        setdid(student.id);
                        setdname(student.fname + " " + student.lname);
                      }}
                      className="btn red"
                    >
                      Obriši
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
