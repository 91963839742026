import React, { useState, useEffect } from "react";
import {
  getCategoryList,
  createCategory,
  editCategory,
  deleteCategory,
} from "../api";
import Modal from "react-modal";

export default () => {
  const [list, setList] = useState([]);

  const [categoryName, setCategoryName] = useState("");

  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const getList = () => {
    getCategoryList()
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();
  }, []);

  const resetFilters = () => {
    setCategoryName("");
  };

  const [cname, setcname] = useState("");
  const [cprice, setcprice] = useState("");
  const [cpriceperclass, setcpriceperclass] = useState("");
  const [cpayments, setcpayments] = useState("");
  const [cclasses, setcclasses] = useState("");

  const create = () => {
    let data = new FormData();
    data.append("name", cname);
    data.append("price", cprice);
    data.append("price_per_class", cpriceperclass);
    data.append("payments", cpayments);
    data.append("classes", cclasses);
    createCategory(data)
      .then(() => setCreateModal(false))
      .then(() => getList())
      .then(() => {
        setcclasses("");
        setcname("");
        setcpayments("");
        setcprice("");
        setcpriceperclass("");
      })
      .catch((err) => console.log(err));
  };

  const [ename, setename] = useState("");
  const [eprice, seteprice] = useState("");
  const [epriceperclass, setepriceperclass] = useState("");
  const [epayments, setepayments] = useState("");
  const [eclasses, seteclasses] = useState("");
  const [eid, seteid] = useState("");

  const edit = () => {
    let data = new FormData();
    data.append("_method", "put");
    data.append("name", ename);
    data.append("price", eprice);
    data.append("price_per_class", epriceperclass);
    data.append("payments", epayments);
    data.append("classes", eclasses);
    editCategory(data, eid)
      .then(() => setEditModal(false))
      .then(() => getList())
      .catch((err) => console.log(err));
  };

  const [did, setdid] = useState("");
  const [dname, setdname] = useState("");

  const _delete = () => {
    deleteCategory(did)
      .then(() => setDeleteModal(false))
      .then(() => getList())
      .catch((err) => console.log(err));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // marginTop: 16,
        padding: 16,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: 16,
        }}
      >
        <input
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          placeholder="Naziv kategorije"
        />
        <span style={{ height: 8 }} />
        {categoryName && (
          <button className="btn gray" onClick={resetFilters}>
            Resetuj filter
          </button>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <button
          style={{
            alignSelf: "flex-end",
          }}
          onClick={() => setCreateModal(true)}
          className="btn blue lg"
        >
          Dodaj kategoriju
        </button>
        <Modal
          ariaHideApp={false}
          overlayClassName="Overlay"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
          }}
          isOpen={createModal}
        >
          <div className="modal">
            <form onSubmit={create}>
              <label>Naziv kategorije</label>
              <input
                required
                value={cname}
                onChange={(e) => setcname(e.target.value)}
              />
              <label>Ukupna cena</label>
              <input
                required
                type="number"
                value={cprice}
                onChange={(e) => setcprice(e.target.value)}
              />
              <label>Broj rata</label>
              <input
                required
                type="number"
                value={cpayments}
                onChange={(e) => setcpayments(e.target.value)}
              />
              <label>Broj časova</label>
              <input
                required
                type="number"
                value={cclasses}
                onChange={(e) => setcclasses(e.target.value)}
              />
              <label>Cena profesora</label>
              <input
                required
                type="number"
                value={cpriceperclass}
                onChange={(e) => setcpriceperclass(e.target.value)}
              />
              <div>
                <button
                  className="btn gray"
                  type="button"
                  onClick={() => setCreateModal(false)}
                >
                  Odustani
                </button>
                &nbsp;
                <button className="btn blue" type="submit">
                  Sačuvaj
                </button>
              </div>
            </form>
          </div>
        </Modal>
        <Modal
          ariaHideApp={false}
          overlayClassName="Overlay"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
          }}
          isOpen={editModal}
        >
          <div className="modal">
            <form onSubmit={edit}>
              <label>Naziv kategorije</label>
              <input
                required
                value={ename}
                onChange={(e) => setename(e.target.value)}
              />
              <label>Ukupna cena</label>
              <input
                required
                type="number"
                value={eprice}
                onChange={(e) => seteprice(e.target.value)}
              />
              <label>Broj rata</label>
              <input
                required
                type="number"
                value={epayments}
                onChange={(e) => setepayments(e.target.value)}
              />
              <label>Broj časova</label>
              <input
                required
                type="number"
                value={eclasses}
                onChange={(e) => seteclasses(e.target.value)}
              />
              <label>Cena profesora</label>
              <input
                required
                type="number"
                value={epriceperclass}
                onChange={(e) => setepriceperclass(e.target.value)}
              />
              <div>
                <button
                  className="btn gray"
                  type="button"
                  onClick={() => setEditModal(false)}
                >
                  Odustani
                </button>
                &nbsp;
                <button className="btn blue" type="submit">
                  Sačuvaj
                </button>
              </div>
            </form>
          </div>
        </Modal>
        <Modal
          ariaHideApp={false}
          overlayClassName="Overlay"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
          }}
          isOpen={deleteModal}
        >
          <div className="modal">
            <form onSubmit={_delete}>
              <p>Sigurno želite da obrišete kategoriju {dname}?</p>
              <div>
                <button
                  className="btn gray"
                  type="button"
                  onClick={() => setDeleteModal(false)}
                >
                  Odustani
                </button>
                &nbsp;
                <button className="btn red" type="submit">
                  Obriši
                </button>
              </div>
            </form>
          </div>
        </Modal>
        <table>
          <thead>
            <tr>
              <td></td>
              <th>Naziv kategorije</th>
              <th className="center">Broj časova</th>
              <th className="center">Cena</th>
              <th className="center">Broj rata</th>
              <th className="center">Cena profesora</th>
              <th style={{ textAlign: "right" }}>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {list
              .filter((course) =>
                categoryName
                  ? course.name
                      .toLowerCase()
                      .includes(categoryName.toLowerCase())
                  : true
              )
              .map((course, i) => (
                <tr key={course.id}>
                  <td>{i + 1}.</td>
                  <td>{course.name}</td>
                  <td className="center">{course.classes}</td>
                  <td className="center">{course.price}</td>
                  <td className="center">{course.payments}</td>
                  <td className="center">{course.price_per_class}</td>
                  <td style={{ textAlign: "right" }}>
                    <button
                      className="btn green"
                      onClick={() => {
                        setename(course.name);
                        seteclasses(course.classes);
                        seteprice(course.price);
                        setepriceperclass(course.price_per_class);
                        setepayments(course.payments);
                        seteid(course.id);
                        setEditModal(true);
                      }}
                    >
                      Izmeni
                    </button>
                    <button
                      className="btn red"
                      onClick={() => {
                        setdid(course.id);
                        setdname(course.name);
                        setDeleteModal(true);
                      }}
                    >
                      Obriši
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
