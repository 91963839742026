import React, { useState, useEffect } from "react";
import { getActiveList, getCategoryList } from "../api";
import { Link } from "react-router-dom";

export default () => {
  const [list, setList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [courseName, setCourseName] = useState("");

  useEffect(() => {
    getActiveList()
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (Array.isArray(list) && list.length > 0)
      getCategoryList()
        .then((res) => {
          setCategoryList(res.data);
        })
        .catch((err) => console.log(err));
  }, [list]);

  const resetFilters = () => {
    setSelectedCategory("");
    setCourseName("");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // marginTop: 16,
        padding: 16,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: 16,
        }}
      >
        <input
          value={courseName}
          onChange={(e) => setCourseName(e.target.value)}
          placeholder="Naziv kursa"
        />
        <span style={{ height: 8 }} />
        <select
          className="category-select"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="">Sve kategorije</option>
          {categoryList
            .filter((category) =>
              list.some((course) => category.id === course.category_id)
            )
            .map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
        </select>
        <span style={{ height: 8 }} />
        {(selectedCategory || courseName) && (
          <button className="btn gray" onClick={resetFilters}>
            Resetuj filtere
          </button>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <table>
          <thead>
            <tr>
              <td></td>
              <th>Naziv kursa</th>
              <th className="center">Broj časova</th>
              <th className="center">Cena</th>
              <th className="center">Broj rata</th>
              <th className="center">Cena profesora</th>
              <th className="center">Ostvareni profit</th>
              <th className="center">Predviđeni profit</th>
              <th className="center">Upisano</th>
              <th style={{ textAlign: "right" }}>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {list
              .filter((course) =>
                selectedCategory
                  ? course.category_id.toString() === selectedCategory
                  : true && courseName
                  ? course.name.toLowerCase().includes(courseName.toLowerCase())
                  : true
              )
              .map((course, i) => (
                <tr key={course.id}>
                  <td>{i + 1}.</td>
                  <td>{course.name}</td>
                  <td className="center">{course.classes}</td>
                  <td className="center">{course.price}</td>
                  <td className="center">{course.payments}</td>
                  <td className="center">{course.price_per_class}</td>
                  <td className="center">
                    {parseFloat(course.realized_profit).toFixed(0)}
                  </td>
                  <td className="center">
                    {parseFloat(course.expected_profit).toFixed(0)}
                  </td>
                  <td className="center">{course.student.length}</td>
                  <td style={{ textAlign: "right" }}>
                    <Link
                      className="btn blue"
                      to={"/kursevi/" + course.id + "/aktivan"}
                    >
                      Pregled
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
