import React, { useState, useEffect } from "react";
import { getStudentList, createStudent } from "../api";
import { Link } from "react-router-dom";
import Modal from "react-modal";

export default () => {
  const [list, setList] = useState([]);

  const [studentName, setStudentName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentPhone, setStudentPhone] = useState("");

  const getList = () => {
    getStudentList()
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();
  }, []);

  const resetFilters = () => {
    setStudentEmail("");
    setStudentName("");
    setStudentPhone("");
  };

  const [cfname, setcfname] = useState("");
  const [clname, setclname] = useState("");
  const [cemail, setcemail] = useState("");
  const [cphone, setcphone] = useState("");
  const [cid, setcid] = useState("");
  const [caddress, setcaddress] = useState("");
  const [cheard, setcheard] = useState("friend");

  const [createModal, setCreateModal] = useState(false);

  const create = () => {
    let data = new FormData();
    data.append("fname", cfname);
    data.append("lname", clname);
    data.append("email", cemail);
    data.append("phone", cphone);
    data.append("id_no", cid);
    data.append("address", caddress);
    data.append("heard_from", cheard);
    createStudent(data)
      .then(() => setCreateModal(false))
      .then(() => getList())
      .then(() => {
        setcfname("");
        setclname("");
        setcemail("");
        setcphone("");
        setcid("");
        setcaddress("");
        setcheard("friend");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // marginTop: 16,
        padding: 16,
      }}
    >
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={createModal}
      >
        <div className="modal">
          <form onSubmit={create}>
            <label>Ime</label>
            <input
              required
              value={cfname}
              onChange={(e) => setcfname(e.target.value)}
            />
            <label>Prezime</label>
            <input
              required
              value={clname}
              onChange={(e) => setclname(e.target.value)}
            />
            <label>Email</label>
            <input
              required
              type="email"
              value={cemail}
              onChange={(e) => setcemail(e.target.value)}
            />
            <label>Telefon</label>
            <input
              required
              value={cphone}
              onChange={(e) => setcphone(e.target.value)}
            />
            <label>Adresa</label>
            <input
              required
              value={caddress}
              onChange={(e) => setcaddress(e.target.value)}
            />
            <label>Lična karta</label>
            <input
              required
              value={cid}
              onChange={(e) => setcid(e.target.value)}
            />
            <label>Kako je čuo</label>
            <select onChange={(e) => setcheard(e.target.value)} value={cheard}>
              <option value="friend">Prijatelj</option>
              <option value="friend_student">Prijatelj polaznik</option>
              <option value="ad">Reklama</option>
              <option value="job">Posao</option>
              <option value="google">Google</option>
              <option value="social_media">Društvene mreze</option>
              <option value="event">Događaj</option>
            </select>
            <div>
              <button
                className="btn gray"
                type="button"
                onClick={() => setCreateModal(false)}
              >
                Odustani
              </button>
              &nbsp;
              <button className="btn blue" type="submit">
                Sačuvaj
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: 16,
        }}
      >
        <input
          value={studentName}
          onChange={(e) => setStudentName(e.target.value)}
          placeholder="Ime i prezime"
        />
        <span style={{ height: 8 }} />
        <input
          value={studentEmail}
          onChange={(e) => setStudentEmail(e.target.value)}
          placeholder="Email"
        />
        <span style={{ height: 8 }} />
        <input
          value={studentPhone}
          onChange={(e) => setStudentPhone(e.target.value)}
          placeholder="Telefon"
        />
        <span style={{ height: 8 }} />
        <span style={{ height: 8 }} />
        {(studentEmail || studentPhone || studentName) && (
          <button className="btn gray" onClick={resetFilters}>
            Resetuj filtere
          </button>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <button
          className="btn blue lg"
          style={{
            alignSelf: "flex-end",
          }}
          onClick={() => setCreateModal(true)}
        >
          Dodaj studenta
        </button>
        <table>
          <thead>
            <tr>
              <td></td>
              <th>Ime i prezime</th>
              <th>Email</th>
              <th>Telefon</th>
              <th style={{ textAlign: "right" }}>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {list
              .filter((student) =>
                studentName
                  ? (
                      student.fname.toLowerCase() +
                      " " +
                      student.lname.toLowerCase()
                    ).includes(studentName.toLowerCase())
                  : true && studentEmail && student.email
                  ? student.email
                      .toLowerCase()
                      .includes(studentEmail.toLowerCase())
                  : true && studentPhone && student.phone
                  ? student.phone
                      .toLowerCase()
                      .includes(studentPhone.toLowerCase())
                  : true
              )
              .map((student, i) => (
                <tr key={student.id}>
                  <td>{i + 1}.</td>
                  <td>
                    {student.fname} {student.lname}
                  </td>
                  <td>{student.email}</td>
                  <td>{student.phone}</td>
                  <td style={{ textAlign: "right" }}>
                    <Link className="btn blue" to={"/studenti/" + student.id}>
                      Pregled
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
