import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getCourse,
  getStudent,
  editContract,
  getRate,
  sign,
  makePayment,
  updatePayment,
} from "../api";
import formatDate from "../helpers/formatDate";
import Modal from "react-modal";

export default () => {
  const { student_id, course_id } = useParams();
  const [course, setCourse] = useState({});
  const [student, setStudent] = useState({});
  const [rate, setRate] = useState({});

  const [pivot, setPivot] = useState(null);

  const [totalPaid, setTotalPaid] = useState(0);

  const getInfo = () => {
    getCourse(course_id)
      .then((res) => {
        setCourse(res.data);
      })
      .catch((err) => console.log(err));
    getStudent(student_id)
      .then((res) => {
        setStudent(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (course_id && student_id) {
      getInfo();
    }
  }, [course_id, student_id]);

  useEffect(() => {
    if (Object.keys(course).length > 0 && Object.keys(student).length > 0) {
      if (
        (Array.isArray(student.active_course) &&
          student.active_course.length > 0) ||
        (Array.isArray(student.finished_course) &&
          student.finished_course.length > 0)
      ) {
        const active = student.active_course.find((c) => c.id === course.id);
        if (active) {
          setPivot(active);
          return;
        }
        const finished = student.finished_course.find(
          (c) => c.id === course.id
        );
        if (finished) {
          setPivot(finished);
          return;
        }
        setPivot(null);
      }
    }
  }, [course, student]);

  useEffect(() => {
    if (
      pivot &&
      Array.isArray(pivot.student_course_payment) &&
      pivot.student_course_payment.length > 0
    ) {
      setTotalPaid(
        pivot.student_course_payment
          .filter((pay) => parseInt(pay.student_id) === parseInt(student_id))
          .reduce((prev, current) => prev + current.pivot.amount, 0)
      );
    }
  }, [pivot, student_id]);

  const contract = () => {
    editContract({
      student_id,
      course_id,
      contract_status: pivot.pivot.contract_status === 0 ? 1 : 0,
    })
      .then(() => getInfo())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getRate()
      .then((res) => {
        setRate({
          eur: res.data.DataCurrency.EUR.MiddleRate,
          usd: res.data.DataCurrency.USD.MiddleRate,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  const [fdiscount, setfdiscount] = useState(0);
  const [fpay, setfpay] = useState("");
  const [fdate, setfdate] = useState(new Date().toISOString().substr(0, 10));
  const [fnext, setfnext] = useState("");
  const [fcurr, setfcurr] = useState("rsd");

  const signStudent = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("discount", fdiscount);
    data.append(
      "price",
      parseInt(course.price - (course.price * fdiscount) / 100)
    );
    data.append("contract_status", 0);
    data.append("student_id", student_id);
    data.append("course_id", course_id);
    data.append(
      "paid",
      fcurr === "eur"
        ? fpay
        : fcurr === "rsd"
        ? parseInt(fpay / rate.eur)
        : parseFloat((fpay * rate.usd) / rate.eur).toFixed(2)
    );
    data.append("planned_payment_date", fnext.substr(0, 10));
    data.append("created_at", fdate.substr(0, 10));
    sign(data)
      .then((res) => {
        getInfo();
        console.log(res);
      })
      .catch((err) => {
        getInfo();
        console.log(err);
      });
  };

  const [npay, setnpay] = useState("");
  const [ndate, setndate] = useState(new Date().toISOString().substr(0, 10));
  const [nnext, setnnext] = useState("");
  const [ncurr, setncurr] = useState("rsd");

  const [payModal, setPayModal] = useState(false);

  const [epay, setepay] = useState("");
  const [edate, setedate] = useState(new Date().toISOString().substr(0, 10));
  const [enext, setenext] = useState("");
  const [ecurr, setecurr] = useState("rsd");
  const [eid, seteid] = useState("");

  const [editPayModal, setEditPayModal] = useState(false);

  const openEditPayModal = (pay) => {
    console.log(pay);
    const { pivot } = pay;
    setepay(parseFloat(pivot.amount * rate.eur).toFixed(2));
    setedate(new Date(pivot.created_at).toISOString().substr(0, 10));
    setenext(new Date(pivot.planned_payment_date).toISOString().substr(0, 10));
    seteid(pivot.id);
    setEditPayModal(true);
  };

  const payment = () => {
    let data = new FormData();
    data.append("student_id", student_id);
    data.append("course_id", course_id);
    data.append("student_course_id", pivot.pivot.id);
    data.append(
      "amount",
      ncurr === "eur"
        ? npay
        : ncurr === "rsd"
        ? parseFloat(npay / rate.eur).toFixed(2)
        : parseFloat((npay * rate.usd) / rate.eur).toFixed(2)
    );
    data.append("planned_payment_date", nnext.substr(0, 10));
    data.append("created_at", ndate.substr(0, 10));

    makePayment(data)
      .then((res) => {
        getInfo();
        setPayModal(false);
      })
      .catch((err) => {
        getInfo();
        console.log(err);
      });
  };

  const editPayment = () => {
    let data = new FormData();
    // data.append("_method", "put");
    // data.append("student_id", student_id);
    // data.append("course_id", course_id);
    data.append("student_course_id", pivot.pivot.id);
    data.append(
      "amount",
      ecurr === "eur"
        ? epay
        : ecurr === "rsd"
        ? parseFloat(epay / rate.eur).toFixed(2)
        : parseFloat((epay * rate.usd) / rate.eur).toFixed(2)
    );
    data.append("planned_payment_date", enext.substr(0, 10));
    data.append("created_at", edate.substr(0, 10));

    updatePayment(eid, data)
      .then((res) => {
        console.log(res);
        getInfo();
        setEditPayModal(false);
      })
      .catch((err) => {
        getInfo();
        console.log(err);
      });
  };

  const [note, setNote] = useState("");

  useEffect(() => {
    if (pivot && pivot.pivot.note) {
      setNote(pivot.pivot.note);
    } else if (
      student &&
      Array.isArray(student.category) &&
      student.category.length > 0
    ) {
      const found = student.category.find(
        (c) => parseInt(c.id) === parseInt(course_id)
      );
      console.log(found);
      if (found && found.pivot && found.pivot.note) {
        setNote(found.pivot.note);
      }
    }
  }, [pivot, student, course_id]);

  const edit = () => {
    let data = new FormData();
    data.append("_method", "put");
    data.append("note", note);
    data.append("student_id", student.id);
    data.append("course_id", course_id);
    sign(data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const [contractModal, setContractModal] = useState(false);

  const [contractType, setContractType] = useState("solution");
  const [contractNumber, setContractNumber] = useState(4);

  return (
    <div>
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={contractModal}
      >
        <div className="modal">
          <form>
            <label>Tip ugovora</label>
            <span
              style={{
                display: "flex",
              }}
            >
              <select
                value={contractType}
                onChange={(e) => setContractType(e.target.value)}
              >
                <option value="solution">ITC Solution</option>
                <option value="studio">ITC Studio</option>
                <option value="itc">IT Centar</option>
              </select>
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 25,
              }}
            >
              <label
                style={{
                  marginBottom: 0,
                }}
              >
                Broj rata
              </label>
              <input
                style={{
                  marginBottom: 0,
                }}
                className="special"
                required
                type="number"
                value={contractNumber}
                onChange={(e) => setContractNumber(e.target.value)}
              />
            </div>
            <div>
              <button
                className="btn gray"
                type="button"
                onClick={() => setContractModal(false)}
              >
                Odustani
              </button>
              &nbsp;
              <a
                onClick={() => setContractModal(false)}
                className="btn blue"
                rel="noopener noreferrer"
                target="_blank"
                href={`http://kursevi.itcentar.rs/download-contract/${student_id}/${course_id}?tip_ugovora=${contractType}&br_rata=${contractNumber}`}
              >
                Preuzmi
              </a>
              {/* <button className="btn blue" type="submit">
                Preuzmi
              </button> */}
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={payModal}
      >
        <div className="modal">
          <form onSubmit={payment}>
            <label>Iznos uplate</label>
            <span
              style={{
                display: "flex",
              }}
            >
              <input
                required
                type="number"
                value={npay}
                onChange={(e) => setnpay(e.target.value)}
              />
              <select
                className="special"
                value={ncurr}
                onChange={(e) => setncurr(e.target.value)}
              >
                <option value="rsd">RSD</option>
                <option value="eur">EUR</option>
                <option value="usd">USD</option>
              </select>
            </span>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Iznos u evrima
              <span
                style={{
                  textAlign: "right",
                  width: 100,
                }}
              >
                {ncurr === "eur"
                  ? npay
                  : ncurr === "rsd"
                  ? parseFloat(npay / rate.eur).toFixed(2)
                  : parseFloat((npay * rate.usd) / rate.eur).toFixed(2)}{" "}
                EUR
              </span>
            </label>
            <label>Datum uplate</label>
            <input
              required
              type="date"
              value={ndate}
              onChange={(e) => setndate(e.target.value)}
            />
            <label>Datum sledeće uplate</label>
            <input
              required
              type="date"
              value={nnext}
              onChange={(e) => setnnext(e.target.value)}
            />
            <div>
              <button
                className="btn gray"
                type="button"
                onClick={() => setPayModal(false)}
              >
                Odustani
              </button>
              &nbsp;
              <button className="btn blue" type="submit">
                Sačuvaj
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        overlayClassName="Overlay"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={editPayModal}
      >
        <div className="modal">
          <form onSubmit={editPayment}>
            <label>Iznos uplate</label>
            <span
              style={{
                display: "flex",
              }}
            >
              <input
                required
                type="number"
                value={epay}
                onChange={(e) => setepay(e.target.value)}
              />
              <select
                className="special"
                value={ecurr}
                onChange={(e) => setecurr(e.target.value)}
              >
                <option value="rsd">RSD</option>
                <option value="eur">EUR</option>
                <option value="usd">USD</option>
              </select>
            </span>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Iznos u evrima
              <span
                style={{
                  textAlign: "right",
                  width: 100,
                }}
              >
                {ecurr === "eur"
                  ? epay
                  : ecurr === "rsd"
                  ? parseFloat(epay / rate.eur).toFixed(2)
                  : parseFloat((epay * rate.usd) / rate.eur).toFixed(2)}{" "}
                EUR
              </span>
            </label>
            <label>Datum uplate</label>
            <input
              required
              type="date"
              value={edate}
              onChange={(e) => setedate(e.target.value)}
            />
            <label>Datum sledeće uplate</label>
            <input
              required
              type="date"
              value={enext}
              onChange={(e) => setenext(e.target.value)}
            />
            <div>
              <button
                className="btn gray"
                type="button"
                onClick={() => setEditPayModal(false)}
              >
                Odustani
              </button>
              &nbsp;
              <button className="btn blue" type="submit">
                Sačuvaj
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <div
        style={{
          margin: 16,
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <label
            style={{
              width: 70,
            }}
          >
            Kurs:
          </label>
          <label>{course.name}</label>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <label
            style={{
              width: 70,
            }}
          >
            Student:
          </label>
          <label>
            {student.fname} {student.lname}
          </label>
        </div>
      </div>
      <form
        onSubmit={edit}
        style={{
          display: "flex",
          alignItems: "center",
          margin: 16,
        }}
      >
        <input
          onBlur={edit}
          placeholder="Napomena"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </form>
      {pivot && (
        <div
          style={{
            margin: 16,
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <label
              style={{
                width: 70,
              }}
            >
              Plaćeno
            </label>
            <label>{parseFloat(totalPaid).toFixed(0)}</label>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <label
              style={{
                width: 70,
              }}
            >
              Preostalo
            </label>
            <label>
              {parseFloat(pivot.pivot.price - totalPaid).toFixed(0)}
            </label>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <label
              style={{
                width: 70,
              }}
            >
              Cena
            </label>
            <label>{parseFloat(pivot.pivot.price).toFixed(0)}</label>
          </div>
        </div>
      )}
      {pivot && (
        <div
          style={{
            margin: 16,
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            {pivot.pivot.contract_status ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ width: 150 }}>Ugovor je potpisan</span>
                <button className="btn red" onClick={contract}>
                  Obriši ugovor
                </button>
                <button
                  className="btn green"
                  onClick={() => setContractModal(true)}
                >
                  Preuzmi ugovor
                </button>
                {/* <a
                  className="btn green"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`http://kursevi.itcentar.rs/download-contract/${student_id}/${course_id}`}
                >
                  Preuzmi ugovor
                </a> */}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ width: 150 }}>Ugovor nije potpisan</span>
                <button className="btn green" onClick={contract}>
                  Potpiši ugovor
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {pivot ? (
        <React.Fragment>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 16,
              }}
            >
              <button className="btn blue" onClick={() => setPayModal(true)}>
                Dodaj uplatu
              </button>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              {pivot &&
                Array.isArray(pivot.student_course_payment) &&
                pivot.student_course_payment.length > 0 && (
                  <table className="table">
                    <caption>Plaćanja</caption>
                    <thead>
                      <tr>
                        <th></th>
                        <th className="center">Datum uplate</th>
                        <th className="center">Iznos</th>
                        <th className="center">Datum sledeće uplate</th>
                        <th style={{ textAlign: "right" }}>Akcije</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pivot.student_course_payment
                        .filter(
                          (pay) =>
                            parseInt(pay.student_id) === parseInt(student_id)
                        )
                        .map((pay, i) => (
                          <tr key={i}>
                            <td>{i + 1}.</td>
                            <td className="center">
                              {formatDate(pay.pivot.created_at)}
                            </td>
                            <td className="center">
                              {parseFloat(pay.pivot.amount).toFixed(0)}
                            </td>
                            <td className="center">
                              {formatDate(pay.pivot.planned_payment_date)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <button
                                className="btn green"
                                onClick={() => openEditPayModal(pay)}
                              >
                                Izmeni
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              // flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: -16,
              marginBottom: 16,
            }}
          >
            {pivot &&
              Array.isArray(pivot.student_course_payment) &&
              pivot.student_course_payment.length > 0 && (
                <>
                  <a
                    className="btn green"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`http://kursevi.itcentar.rs/students/download-receipt/${student_id}/${course_id}?tip=solution`}
                  >
                    Preuzmi ITC Solution uplatnicu
                  </a>
                  <a
                    className="btn green"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`http://kursevi.itcentar.rs/students/download-receipt/${student_id}/${course_id}?tip=studio`}
                  >
                    Preuzmi ITC Studio uplatnicu
                  </a>
                  <a
                    className="btn green"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`http://kursevi.itcentar.rs/students/download-receipt/${student_id}/${course_id}?tip=itc`}
                  >
                    Preuzmi IT Centar uplatnicu
                  </a>
                </>
              )}
          </div>
        </React.Fragment>
      ) : (
        <div
          style={{
            margin: 16,
          }}
        >
          <strong>Prva uplata</strong>
          <form onSubmit={signStudent} className="myform">
            <div>
              <label>Ostvareni popust</label>
              <input
                value={fdiscount}
                onChange={(e) => setfdiscount(e.target.value)}
                type="number"
                required
              />
            </div>
            <div>
              <label>Ukupna cena</label>
              <span style={{ width: 300 }}>
                <small style={{ marginRight: 16 }}>
                  {parseFloat(
                    course.price - (course.price * fdiscount) / 100
                  ).toFixed(2)}{" "}
                  EUR
                </small>
                <small style={{ marginRight: 16 }}>
                  {parseFloat(
                    (course.price - (course.price * fdiscount) / 100) * rate.eur
                  ).toFixed(2)}{" "}
                  RSD
                </small>
                <small>
                  {parseFloat(
                    ((course.price - (course.price * fdiscount) / 100) *
                      rate.eur) /
                      rate.usd
                  ).toFixed(2)}{" "}
                  USD
                </small>
              </span>
            </div>
            <div>
              <label>Iznos uplate</label>
              <input
                value={fpay}
                onChange={(e) => setfpay(e.target.value)}
                type="number"
                required
                style={{
                  width: 140,
                }}
              />
              <select
                style={{
                  width: 60,
                }}
                value={fcurr}
                onChange={(e) => setfcurr(e.target.value)}
              >
                <option value="rsd">RSD</option>
                <option value="eur">EUR</option>
                <option value="usd">USD</option>
              </select>
            </div>
            <div>
              <label>Iznos u evrima</label>
              <small>
                {fcurr === "eur"
                  ? fpay
                  : fcurr === "rsd"
                  ? parseFloat(fpay / rate.eur).toFixed(2)
                  : parseFloat((fpay * rate.usd) / rate.eur).toFixed(2)}{" "}
                EUR
              </small>
            </div>
            <div>
              <label>Datum uplate</label>
              <input
                value={fdate}
                onChange={(e) => setfdate(e.target.value)}
                type="date"
                required
              />
            </div>
            <div>
              <label>Datum sledeće uplate</label>
              <input
                value={fnext}
                onChange={(e) => setfnext(e.target.value)}
                type="date"
                required
              />
            </div>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
              }}
            >
              <button className="btn blue" type="submit">
                Dodaj uplatu i upiši na kurs
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
